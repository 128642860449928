@--neutral-neutral-00: #ffffff;
@--neutral-neutral-10: rgba(243, 244, 246, 1);
@--neutral-neutral-20: rgba(223, 226, 231, 1);
@--neutral-neutral-30: rgba(194, 199, 208, 1);
@--neutral-neutral-40: rgba(163, 169, 185, 1);
@--neutral-neutral-60: rgba(105, 111, 136, 1);
@--neutral-neutral-70: rgba(77, 81, 101, 1);
@--neutral-neutral-90: rgba(35, 38, 48, 1);
@--neutral-neutral-100: rgba(20, 21, 28, 1);
@ant-prefix: syngenta-ant;
@--green-green-10:rgba(223, 251, 232, 1);
@--green-green-40:rgba(25, 160, 75, 1);
@--green-green-50: #19a04b;
@--green-green-60:rgba(20, 128, 60, 1);
@--light-black-85:rgba(0, 0, 0, 0.85);
@--light-gray-15:rgba(0, 0, 0, 0.15);
@--light-gray-20:rgba(105, 111, 136, 1);
@--blue-blue-10: rgba(234, 246, 255, 1);
@--blue-blue-20: rgba(198, 230, 255, 1);
@--blue-blue-40: rgba(49, 180, 242, 1);
@--blue-blue-80:rgba(49, 180, 242, 0.3);
@--blue-blue-50: rgba(0, 146, 228, 1);
@--blue-blue-70: rgba(0, 113, 205, 1);
@--blue-blue-90:rgba(0, 86, 147, 1);
@--blue-blue-60:rgba(0, 113, 205, 1);
@--red-red-50: #eb4b4b;
@--red-red-60: rgba(207, 53, 55, 1);
@--box-shadow-grey: #00000026;
@--gray-gray-20: #515253;
@--font-family: "Noto Sans",sans-serif ;


.ab-blue {
  background-color: #0092E4 !important;
}

.ab-orange {
  background-color: #EE5B3A !important;
}

.ab-purple {
  background-color: #9664F0 !important;
}

.ab-pink {
  background-color: #E85D78 !important;
}

.ab-teal {
  background-color: #2B9C92 !important;
}

.ab-yellow {
  background-color: #C17E19 !important;
}

.text-semibold {
  font-weight: 600 !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.text-nomargin{
  margin:0 !important;
}

.text-neutral-60 {
  color: @--neutral-neutral-60 !important;
}

.text-sm {
  color: @--gray-gray-20;
  /* Regular / 12px | 20px */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.09px;
  margin:0;
}

.text-md {
  /* Regular / 14px | 20px */
  color: @--neutral-neutral-100;
  font-family: "Noto Sans",sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.105px;
  margin-left: 11px;
}

.text-lg {
  /* Semibold / 16px | 24px */
  color: @--neutral-neutral-100;
  font-size: 16px;
  font-style: normal;
  line-height: 24px; /* 150% */
  letter-spacing: -0.15px;
}

.text-xl {
  /* Semibold / 20px | 28px */
  color: @--neutral-neutral-100;
  font-size: 20px;
  font-style: normal;
  line-height: 28px; /* 140% */
  letter-spacing: -0.15px;
  margin:0;
}

.text-2xl {
  color: @--neutral-neutral-100;
  font-size: 24px;
  font-style: normal;
  line-height: 32px; /* 140% */
  letter-spacing: -1.25%;
  margin:0;
}