@import "../../config/styles/theme.less";


.protocols-dashboard-page {
    button {
        outline: 0;
        border: 0;
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }
    }

    .protocols-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        background-color: @--neutral-neutral-10 ;

        h3 {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 600;
        }

        button {
            padding: 10px 16px;
            display: flex;
            gap: 4px;
            cursor: pointer;
            align-items: center;
            background-color: #14803C;
            color: white !important;
        }

        button:hover {
            color: white !important;
        }
    }

    .body-content-protocol {
        height: calc(100vh - 112px);
        background-color: white;
        width: 100%;
        padding: 24px;
        padding-bottom: 0;
        overflow-x: hidden;
        position: relative;

        .protocols-search-container {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            column-gap: 8px;
        }

        .protocol-empty-state-container {
            display: flex;
            padding: 80px 0px;
            align-items: center;

            .empty-state-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 24px;

                .empty-state-heading {
                    text-align: center;
                    gap: 12px;

                    h2 {
                        color: var(--Neutral-neutral-100, #14151C);
                        text-align: center;
                        font-family: "Noto Sans",sans-serif;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 32px;
                        margin-bottom: 12px;
                        letter-spacing: -0.3px;
                    }

                    p {
                        color: var(--Neutral-neutral-90, #232630);
                        text-align: center;
                        font-family: "Noto Sans",sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        margin: 0;
                        letter-spacing: -0.12px;
                    }
                }

                button {
                    padding: 10px 16px;
                    display: flex;
                    gap: 4px;
                    cursor: pointer;
                    align-items: center;
                    background-color: #14803C;
                    color: white !important;
                }
            }
        }

        .ant-table {
            height: calc(100vh - 249px);
            overflow-x: hidden;
            overflow-y: scroll;
        }

        .ant-table-pagination.ant-pagination {
            margin: 0;
            padding: 16px 0;
            border-top: 1px solid var(--Neutral-neutral-20, #D9DBE9);
        }
    }

    .flex-content-protocol {
        background-color: white;
        display: flex;
        padding: 24px;
        justify-content: space-between;
        padding-bottom: 0;

        .protocols-filter-btn {
            padding: 5px;
            width: 40px;
            margin-left: auto;
            flex-shrink: 0;
            border-radius: 4px;
            background: var(--Neutral-neutral-10, #F3F4F6);
        }
    }

    .pt-0{
        padding-top: 0;
    }

    .status-column {
        padding: 0px 8px;
        border-radius: 4px;
        font-family: "Noto Sans",sans-serif;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: -0.09px;
    }

    .protocol-published {
        border: 1px solid var(--Blue-blue-60, #0071CD);
        color: #0071CD;
    }

    .protocol-draft {
        border: 1px solid var(--Neutral-neutral-60, #696F88);
        color: #696F88;
    }

    .ant-tag {
        font-family: "Noto Sans",sans-serif;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        padding: 0px 8px;
        letter-spacing: -0.09px;
    }

    .seedType {
        color: #0C612C;
        border: 1px solid var(--Green-green-20, #C3EAD1);
        background: var(--Green-green-20, #C3EAD1);
    }

    .nutrientType {
        color: #643AAA;
        border: 1px solid var(--Purple-purple-20, #E7DDFC);
        background: var(--Purple-purple-20, #E7DDFC);
    }

    .cropProtectionType {
        color: #744A0B;
        border: 1px solid var(--Red-red-20, #FFE4AE);
        background: var(--Red-red-20, #FFE4AE);
    }

}
@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;