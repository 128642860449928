@import "../config/styles/theme.less";

.hall-of-org-main {
  padding: 30px 40px;
  height: 100%;

  .card_title {
    display: flex;
    gap: 30px;
    align-items: center;

    h2 {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .card-main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 30px;
    gap: 28px;
  }
}

.hall-of-farmes {
  .card-main {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0;

    .farm-card {
      width: 100%;
      height: fit-content;
      padding: 10px;

      .syngenta-ant-card-body {
        display: flex;
        align-items: center;
        //grid-template-columns: repeat(4, 1fr);
      }
    }

    .get-button {
      cursor: pointer;
    }
  }

  .card_title {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-bottom: 32px;

    .Left-button-icon {
      background: transparent;
      padding: 0;
      border: 0;
      box-shadow: none;
    }
  }

  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .card_title {
      margin-bottom: 0;
      gap: 16px;

      h2 {
        width: max-content;
        white-space: nowrap;
      }

      .info-icon {
        width: 20px;
      }

      .ant-input-affix-wrapper {
        width: 100%;
      }
    }

    .farm-btn {
      outline: 0;
      border: 0;
      padding: 10px 16px;
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  .section-main {
    margin-bottom: 48px;
    margin-top: 28px;

    .title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
    }

    .card-main {
      .farm-card {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
          height: 40px;
          border-radius: 0 10px 10px 0;
          background-color: @--green-green-60;
        }
      }
    }
  }

  .no-result-data {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 45px;

    p {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}

.ant-spin.ant-spin-lg {
  gap: 12px;

  >span {
    order: 2;
  }

  .ant-spin-text {
    order: 1;
  }
}

.newTrail-page {
  .newTrail-header {
    .header-main {
      display: grid;
      grid-template-columns: repeat(6, 1fr);

      .breadcrumbs {
        grid-column: span 2 / span 2;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 20px 24px;

        @media (max-width: 991px) {
          grid-column: span 2 / span 2;
        }

        @media (max-width: 767px) {
          grid-column: span 6 / span 6;
          padding-bottom: 0;
        }

        .Left-button-icon {
          box-shadow: none;
          outline: none;
          border: 0;
          cursor: pointer;
          background-color: transparent;
        }

        p {
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .stepper-common {
        grid-column: span 4 / span 4;
        padding: 24px 24px;
        width: 90%;
        margin: auto;

        @media (max-width: 991px) {
          width: 100%;
        }

        @media (max-width: 767px) {
          grid-column: span 6 / span 6;
          padding: 20px 24px;
        }

        .ant-steps-item {
          .ant-steps-item-icon {
            border-color: @--neutral-neutral-40;
            background-color: #fff;
          }
        }

        .ant-steps-item.ant-steps-item-active {
          .ant-steps-item-icon {
            background-color: @--blue-blue-70;
            border-color: transparent;
          }

          .ant-steps-item-title {
            font-weight: 600;

            &:after {
              background-color: @--blue-blue-70;
            }
          }
        }
      }
    }

    .newTrail-data {
      height: calc(100vh - 128px);
      display: grid;
      grid-template-columns: repeat(6, 1fr);

      .left-sec {
        grid-column: span 2 / span 2;

        @media (max-width: 991px) {
          grid-column: span 3 / span 3;
        }

        @media (max-width: 640px) {
          grid-column: span 6 / span 6;
        }

        .inner-sec {
          background-color: @--neutral-neutral-00;
          padding: 20px 16px 20px 24px;
          height: 100%;

          >div {
            height: 100%;

            form {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .scroll-sec {
                height: auto;
                max-height: calc(100vh - 220px);
                overflow-y: auto;
                padding-right: 8px;

                .field-map {
                  .title {
                    margin-bottom: 2px;

                    span {
                      color: red;
                    }
                  }

                  .card-main {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                  }
                }

                .rates-main {
                  .title {
                    font-size: 16px;
                    font-weight: 600;
                    color: @--neutral-neutral-100;
                    margin-bottom: 8px;
                  }

                  .description {
                    color: @--neutral-neutral-70;
                    font-size: 14px;
                    letter-spacing: -0.75%;
                    line-height: 20px;
                  }

                  .ant-form-item {
                    margin-bottom: 0;
                  }
                }
              }

              .scroll-sec-3 {
                height: auto;
                max-height: calc(100vh - 240px);
                overflow-y: auto;
                padding-right: 8px;

                .field-map {
                  .title {
                    margin-bottom: 2px;

                    span {
                      color: red;
                    }
                  }

                  .card-main {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                  }
                }

                .rates-main {
                  .title {
                    font-size: 16px;
                    font-weight: 600;
                    color: @--neutral-neutral-100;
                    margin-bottom: 8px;
                  }

                  .description {
                    color: @--neutral-neutral-70;
                    font-size: 14px;
                    letter-spacing: -0.75%;
                    line-height: 20px;
                  }

                  .ant-form-item {
                    margin-bottom: 0;
                  }
                }
              }

              .scroll-sec:has(.summary-main) {
                padding-left: 8px;
              }
            }
          }

          .bottom-sec-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            button {
              padding: 10px 16px;
              border-radius: 4px;
              font-size: 14px;
              display: flex;
              align-items: center;

              &:focus {
                box-shadow: none;
              }

              &:first-child {
                border: 1px solid @--neutral-neutral-30;
                background-color: transparent;
                gap: 5px;
              }
            }

            .next-btn {
              gap: 8px;
              border: 1px solid @--green-green-60;
              background-color: @--green-green-60;
              color: @--neutral-neutral-00;

              svg {
                fill: @--neutral-neutral-00;
              }
            }

            .next-btn.active {
              background-color: @--neutral-neutral-10;
              border: 1px solid @--neutral-neutral-20;
              color: @--neutral-neutral-30;
              pointer-events: none;

              svg {
                fill: @--neutral-neutral-30;
              }
            }
          }

          .ant-form-item-label {
            label {
              &::before {
                right: -4px;
                position: absolute;
              }
            }
          }
        }

        .form-label {
          span {
            color: @--red-red-60;
          }
        }

        .grid-row {
          display: flex;
          flex-direction: column;
        }

        .inner-sec.remove-padding {
          padding: 20px 0;

          .scroll-sec {
            padding-right: 0;
          }

          .bottom-sec-btn {
            padding: 0 20px;
          }
        }
      }

      .right-sec {
        position: relative;
        height: auto;
        z-index: 0;
        grid-column: span 4 / span 4;

        @media (max-width: 991px) {
          grid-column: span 3 / span 3;
        }

        @media (max-width: 640px) {
          grid-column: span 6 / span 6;
        }
      }

      .btn-map {
        position: absolute;
        z-index: 20;
        top: 15px;
        left: -15px;
        border-radius: 50%;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .map-open {
        width: 100%;
        height: 100vh;
        margin-left: 20px;
      }

      .left-sec.hide-map {
        grid-column: span 6 / span 6;
      }
    }

    .newTrail-data.map-open {
      display: flex;
      width: 100%;

      .left-sec.map-open {
        display: none;
      }
    }
  }

  .summary-main {
    >.title {
      font-size: 16px;
      font-weight: 600;
      color: @--neutral-neutral-100;
      margin-bottom: 8px;
    }

    >.description {
      color: #4d5165;
      font-size: 14px;
      line-height: 20px;
    }

    .summary-card-main {
      display: flex;
      gap: 16px;
      flex-direction: column;
      padding-bottom: 10px;

      .card-1 {
        padding: 16px;
        background-color: @--neutral-neutral-00;
        border-radius: 12px;
        box-shadow: 0 4px 12px 0 @--light-gray-15;

        >h3 {
          font-size: 16px;
          font-weight: 600;
          color: @--neutral-neutral-100;
          margin-bottom: 8px;
        }

        .card-info-sec {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          margin-top: 16px;

          .info {
            h5 {
              font-size: 14px;
              font-weight: 600;
              color: @--neutral-neutral-100;
              margin-bottom: 0;
              margin-left: 11px;
            }

            p {
              font-size: 14px;
              margin-bottom: 0;
              margin-left: 11px;
            }
          }
        }

        .circle-container {
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          .color-circle {

            background-color: #ffff;
            min-width: 16px;
            min-height: 16px;
            height: 16px !important;
            width: 16px !important;
            border-radius: 50px;
            margin: 0;
          }
        }

        .summary-rate-plot-text {
          text-wrap: nowrap;
          margin: 0;
          display: flex;
          align-items: center;
        }

        .summary-rate-text {
          margin: 0;
          display: flex;
          align-items: center;
        }
        .collapse-one{
          .plots-col{
            h4{
              margin-bottom: 0px;
            }
          }
        }
      }
    }

    // unused
    .plot-design-summary-main {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .summary-plot-design {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      .summary-ab-line{
        margin-left: 8px;
      }
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }

    .ant-collapse-header {
      padding: 0;
      display: flex;
      align-items: center;
    }
  }

  .rates-dosage-main {
    .title-sec,
    .ant-tabs-nav-wrap {
      padding-left: 16px;
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        margin-left: 8px;
        color: @--neutral-neutral-30;
        padding: 12px 4px;
      }

      .ant-tabs-tab:first-child {
        margin-left: 0;
      }
    }

    .main-title {
      font-weight: bold;
      font-weight: 600;
      font-size: 15px;
    }

    .rates-dosage {
    }

    .label-style {
      height: 20px;
      width: 40px;
    }

    .plots-collapse-main {
      padding: 0 0.5rem 0 0.5rem;
      .collpase-one {
        margin-bottom: 2px;
        .ml-15{
          margin-left: -15px;
        }
        .plots-col {
          display: flex;
          gap: 8px;
          align-items: center;

          .circle {
            background-color: #ffff;
            height: 16px;
            width: 16px;
            border-radius: 50px;
          }

          h4 {
            margin: 0;
            font-weight: 600;
            size: 14px;
            line-height: 20px;
            color: @--neutral-neutral-100;
          }

          .divider {
            width: 1px;
            height: 20px;
            background-color: @--neutral-neutral-30;
          }

          span {
            font-weight: 400;
            line-height: 20px;
            color: @--neutral-neutral-100;
            font-size: 14px;
          }
        }

        .ant-collapse {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .ant-collapse-item {
            background-color: white !important;
            border-bottom: 1px solid #dfe2e7 !important;

            .ant-collapse-content-box {
              padding: 12px 16px;
            }
          }
        }
      }
    }

    .input-main {
      display: flex;
      flex-direction: row;
      gap: 12px;

      .error-message {
        color: @--red-red-60;
      }

      .input-variety {
        width: 100%;
        gap: 2px;

        p {
          font-weight: 400;
          size: 14px;
          line-height: 20px;
          margin-bottom: 2px;
        }

        .select-item {
          width: 100%;
          height: 40px;
        }
      }

      .input-seed {
        width: 147px;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 2px;
        }

        .input-number {
          height: 40px;
          display: flex;
          align-items: center;
          width: 147px;
        }

        .syngenta-ant-input-number {
          border: 1px solid @--neutral-neutral-30;

          .syngenta-ant-input-number-handler {
            border: 1px solid @--neutral-neutral-30;
          }
        }

        .syngenta-ant-input-number .syngenta-ant-input-number-handler-wrap {
          opacity: 1;
        }
      }
    }

    .ant-collapse-item {
      border-radius: 0;
      border: none;
    }

    .rating-dropdown-container {
      padding-right: 8px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .plots-title {
        font-weight: bold;
        font-size: 14px;
        color: @--neutral-neutral-100;
        margin-bottom: 0;
      }

      .rates-select {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        .rates-title {
          margin-bottom: 0;
          font-weight: bold;
          font-size: 14px;
          color: @--neutral-neutral-100;
        }
      }
    }
  }

  .map {
    position: absolute;
    width: 100%;
    height: 100%;

    @media (max-width: 640px) {
      height: 300px;
    }

    .mapboxgl-canvas {
      width: 100% !important;
    }
  }

  .mapboxgl-map {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    top: unset !important;
    bottom: unset !important;
  }

  .mapboxgl-ctrl-group {
    border-radius: 1px;
  }

  .mapboxgl-ctrl-group button {
    width: 40px;
    height: 40px;
  }

  .mapboxgl-ctrl-group button:focus {
    box-shadow: none;
  }

  .mapboxgl-ctrl-group >.mapboxgl-ctrl-icon {
    width: 40px;
    height: 40px;
  }

  .mapboxgl-ctrl .mapboxgl-ctrl-group {
    border-radius: 8px !important;
  }

  .mapboxgl-ctrl-group >.mapboxgl-ctrl-icon > button {
    width: 24px !important;
    height: 24px !important;
    border-radius: 8px !important;
  }

  .mapboxgl-ctrl-compass-arrow {
    margin: 0.1em 2px !important;
  }
}

.delete-plot-modal {
  width: fit-content !important;

  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-content {
    padding: 25px;

    .title-sec {
      display: flex;
      gap: 20px;
      padding-right: 20px;

      svg {
        margin-top: 2px;
        color: @--red-red-60;
        font-size: 20px;
      }

      .modal-title {
        font-size: 16px;
        font-weight: 600;
        color: @--neutral-neutral-100;
        margin-bottom: 8px;
      }

      .modal-desc {
        font-size: 14px;
        font-weight: 400;
        color: @--neutral-neutral-100;
      }
    }

    .btn-sec {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: flex-end;
      margin-top: 24px;
      margin-bottom: -15px;

      button {
        padding: 7px 14px;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        font-size: 14px;

        &:focus {
          box-shadow: none;
        }

        &:first-child {
          border: 1px solid @--neutral-neutral-30;
          color: @--neutral-neutral-100;
          background-color: transparent;
        }

        &:last-child {
          border: 1px solid @--neutral-neutral-30;
          color: @--neutral-neutral-00;
          background-color: @--red-red-60;

          .syt-antd-btn-loading-icon {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.ct-bg-red {
  background-color: #CF3537 !important;
}

.ct-text-white {
  color: #ffffff !important;
}

.ant-table-tbody{
  .new-empty-state-content{
      .new-empty-state-title{
          color: #14151C;
          font-family: Noto Sans;
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: -0.0125em;
          text-align: center;
      }
      .new-empty-state-p{
          font-family: Noto Sans;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.0075em;
          text-align: center;
      }
  }
}

@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;